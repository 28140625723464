import * as React from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import ReactFilterTable from '../../../components/tables/react_table_filters/react_table_filters'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EVENT_TYPE, FIELDS, LABELS, STATUS_BADGE_CLASS, STATUS_LABELS } from './constant'
import { PERMISSIONS, ROLES } from '../../../rbac/constant'
import { AuthContext } from '../../../auth'
import { Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { putRequest } from '../../../actions/common'
import { STATUS_ACCEPT, STATUS_NEW } from './constant'
import { Messages } from '../../../utils/utils'
import { useAbac } from 'react-abac'

const School = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const [school, setSchool] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'status', 'event_name', 'event_type', 
            'project', 'event_info', 'deadline','organization', 'region', 'count_pupils'],
        'Скрытые / Фиксированные колонки': [
            'month',
            'year',
            'messages',
            'updated_by',
            'created_at',
            'created_by',
            'updated_at',
            'docs_title',
        ],
    })

    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({ status_not: STATUS_NEW })
        }
    }, [user])

    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        }
        if (key === 'event_type') {
            return EVENT_TYPE.find((v) => v?.value === field)?.['label']
        }
        if (key === 'is_network') {
            return IS_NETWORK.find((v) => v?.value === field)?.['label']
        }
        if (['speciality', 'project'].includes(key) && field) {
            return <span>{field.title_short}</span>
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'publication'}/>
        }

        return null
    }

    const addNewSchool = (state) => {
        putRequest('school', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW,
            project: user?.project?.id,
            is_draft: true,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/school/item/${response.data.id}/?update=true`)
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                Школьники
            </h1>
            {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.project_manager)
            || user?.roles?.includes(ROLES.direction)
                ? <React.Fragment>
                    <div className={'button-actions'}>
                        {!user?.roles?.includes(ROLES.direction)
                            ? <Button variant={'success'} className={'mb-3'} onClick={() => {
                                addNewSchool()
                            }}>
                                <IconText icon={'plus'} text={'Создать'}/>
                            </Button> : null}
                        {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction)
                            ? <Button variant={'secondary'} className={'mb-3'} onClick={() => {
                                addNewSchool({ monitoring: true })
                            }}>
                                <IconText icon={'plus'} text={'Добавить в мониторинг'}/>
                            </Button> : null}
                    </div>
                </React.Fragment> : null}
            <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                              filtering={filtering}
                              data={school} setFunc={setSchool} page={1} url={'/school'}
                              url_update={'/school/item'}
                              get_title={'school'}
                              checkField={checkField}
                              permission={PERMISSIONS.MANAGE_SCHOOL}
                              urlImport={'/api/niokr_import_request/'}
                              templateFile={'import_school_template.xlsx'}
                              fields={FIELDS}
            />
        </React.Fragment>
    )
}

export default School
