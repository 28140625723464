import * as React from 'react'
import { Dropdown, Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconText } from '../../icon_txt'
import { useState } from 'react'
import { UploadFile } from '../../../components/file_manager/file_handlers'
import { uploadFile } from '../../../actions/file_manager'
import { toast } from 'react-toastify'
import { DownloadFile } from '../../file_manager/file_handlers'

export const AppoveImport = ({ file, 
                               setFile,
                               showModal, 
                               setShowModal, 
                               getTitle,
                               urlImport, 
                               setNeedReload, 
                               setShowLoading,
                            }) => {

    const handleChangeValueImport = ( e, approveImportFile ) => {
        e.preventDefault()
        if (urlImport) {
            if (e.target.type === 'file' 
                && e.target.files[0] !== file ) {
                setFile(e.target.files[0])
            }
            
            let formData = new FormData()
            formData.append('file', file)
            
            setShowModal(!approveImportFile)  
            if (approveImportFile) {
                setShowLoading(true)
                setShowModal(false)
                uploadFile(`/api/${getTitle}_import/`, formData, () => {})
                    .then((response) => {
                        if (response?.status === 200) {
                            setNeedReload(true)
                            if (response?.data.errors) {
                                toast.error(response?.data.errors)
                            }
                            if (response?.data.logs) {
                                toast.info(response?.data.logs)
                            }
                            if (!response?.data.errors && ! response?.data.logs) {
                                toast.success('Данные успешно сохранены')
                            }
                            
                        } else if (!response) {
                            toast.warning('Время ожидания истекло')
                        }
                        setShowLoading(false)
                    })   
            }    
        }
    }

    return (
        <>
            <Modal size={'md'} show={showModal} centered={true}
                   onHide={() => setShowModal(false)}
            >
                <div className={'modal-content'}>
                    <Modal.Header>
                        <Modal.Title>
                            Вы действительно хотите импортировать файл? <br/>
                            Файл: {file?.name}
                        </Modal.Title>
                        
                        <button className={'close'} 
                                onClick={() => setShowModal(false)}
                        >
                            <FontAwesomeIcon icon={'times'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'button-actions'}>
                            <Button variant={'success'}
                                    onClick={(e) => handleChangeValueImport(e, true)}>
                                <IconText icon={'plus'} text={'Импортировать'}/>
                            </Button>
                            <Button variant={'warning'} 
                                    onClick={() => setShowModal(false)}
                            >
                                Отменить
                            </Button>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
            <UploadFile
                handleChangeValue={(e) => handleChangeValueImport(e, false)}
                file_name={file?.name}
                id={'upload_file'}
                displayNone={true}
            />
        </>
    )
}

export const ImportTable = ({ getTitle,
                              templateFile, 
                              file,
                              setShowModal,
                            }) => {

    return (
        <Dropdown className={'mr-1 d-inline-block'}>
            <Dropdown.Toggle variant={'outline-secondary'}>
                <FontAwesomeIcon icon={'file-import'}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>
                    Импорт данных в текущую базу данных: <br/>
                    Если вы хотите загрузить данные с помощью таблицы Excel, <br/>
                    то необходимо выполнить следующие действия:
                </Dropdown.Header>
                <Dropdown.Item>
                    1. Скачайте шаблон таблицы Excel:&nbsp;
                    <DownloadFile folder={`template_${getTitle}`} 
                                  file={templateFile}
                                  title={getTitle}
                                  withIcon={false}
                    />
                </Dropdown.Item>
                <Dropdown.Item>
                    2. Заполните шаблон необходимыми данным
                </Dropdown.Item>
                <Dropdown.Item>
                    3. Загрузите заполненный шаблон на платформу:
                    <UploadFile handleChangeValue={() => setShowModal(true)}
                                file_name={file?.name}
                                id={'upload_file'}
                    />
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
