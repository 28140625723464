import { IconText } from '../icon_txt'
import { Link } from 'react-router-dom'
import * as React from 'react'

import { Button } from 'react-bootstrap'
import { getFile } from '../../actions/file_manager'

export const DownloadFile = ({
                                 folder,
                                 file,
                                 title,
                                 className,
                                 label,
                                 fileAsLabel = false,
                                 withIcon = true,
                             }) => {
    const downloadHandler = (e) => {
        e.preventDefault()
        getFile(folder, file)
    }

    if (file != null) {
        let icon_color = 'grey', icon = 'file-alt'
        let fileExt = file.split('.').pop()
        if (fileExt === 'xlsx') {
            icon_color = 'green'
            icon = 'file-excel'
        }
        if (fileExt === 'docx' || fileExt === 'doc') {
            icon_color = '#0056b3'
            icon = 'file-alt'
        }

        const WithIcon = () => {
            return (
                <div className={className}>
                    {label}
                    <Link to={'#'} onClick={downloadHandler} data-file-name={`${folder}/${file}`}>
                        <IconText icon={icon}
                                  icon_color={icon_color}
                                  props={{ 'data-file-name': `${folder}/${file}` }}
                                  text={fileAsLabel ? file : 'Скачать'}
                        />
                    </Link>
                </div>
            )
        }

        const WithoutIcon = () => {
            return (
                <Link to={'#'} onClick={downloadHandler} data-file-name={`${folder}/${file}`}>
                    Скачать
                </Link>
            )
        }

        return withIcon ? <WithIcon/> : <WithoutIcon/>
    }
}

export const UploadFile = ({
                               handleChangeValue = null,
                               id,
                               disabled,
                               file_name,
                               data_change_key,
                               small_text,
                               error,
                               accept,
                               displayNone = false,
                           }) => {

    const handleUpload = (e) => {
        if (handleChangeValue) {
            handleChangeValue(e)
        }
    }

    return (
        <div>
            <Button variant={error ? 'danger' : 'primary'}
                    disabled={disabled}
                    style={{ display: !displayNone ? true : 'none' }}
                    onClick={() => {
                        document.getElementById(id).click()
                    }}>
                Выбрать файл
            </Button>
            {small_text ? <small style={{ display: 'block' }}>{small_text}</small> : null}
            {file_name
                ? <small style={{ display: !displayNone ? 'block' : 'none' }}>
                    Выбранный файл: {file_name}
                </small>
                : null}
            {error ? <small className='form-text text-danger'>{error}</small> : null}
            <input type={'file'} style={{ display: 'none' }}
                   onChange={(e) => {
                       handleUpload(e)
                   }
                   }
                   id={id}
                   disabled={disabled}
                   data_change_key={data_change_key}
                   accept={accept}
            />
        </div>
    )
}

export const FileList = ({ files, folder, title, fileAsLabel }) => {
    return files?.map((file) => (
        <DownloadFile key={file.name} folder={folder} file={file.name} title={title} fileAsLabel={fileAsLabel}/>
    ))
}
