import {Button, Modal} from "react-bootstrap";
import {IconText} from "../../icon_txt";
import ReactTable from "../../tables/react_table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormikProvider, useFormik} from "formik";
import {Input} from "../../form/forms";
import * as React from "react";
import {useState} from "react";
import {getUuid} from "../../../utils/utils";
import {MyFileBrowser} from "../../file_manager/file_manager";
import {FileList} from "../../file_manager/file_handlers";

export const CurrentInformation = ({formik, id}) => {
    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState('new')

    const formikHelper = useFormik({
        initialValues: {
            location: '',
            name: '',
            year: '',
            cost: '',
            details: '',
            usage: '',
            files_url: '',
            files: [],
            comment: '',
        }
    })

    const labels = {
        location: 'Местоположение оборудования/ПО',
        name: 'Наименование оборудования/ПО',
        year: 'Год покупки',
        cost: 'Стоимость (руб.)',
        details: 'Реквизиты закупочной документации',
        usage: 'Использование',
        files_url: 'Прикрепленные документы, ссылка',
        files: 'Прикрепленные документы',
        comment: 'Комментарии',
        edit: '',
        remove: ''
    }

    const handleOpenModalInformation = (type, element) => {
        setModalType(type)
        if (element) {
            formikHelper.setValues({...element})
        } else {
            formikHelper.setValues({...formik.initialValues, uuid: getUuid()})
        }
        setShowModal(true)
    }

    const additionalCheckField = (field, key, element) => {
        if (key === 'remove') {
            return <Button variant={'danger'} onClick={() => removeRow(element?.uuid)}>
                <FontAwesomeIcon icon={'trash-alt'}/>
            </Button>
        } else if (key === 'edit') {
            return <Button variant={'primary'} onClick={() => handleOpenModalInformation('edit', element)}>
                <FontAwesomeIcon icon={'pencil-alt'}/>
            </Button>
        } else if (key === 'files') {
            return <FileList files={field} title={`educational_spaces`}
                             folder={`storage/educational_spaces/educational_spaces_${id}/current/${element?.uuid}`} />
        }
        return null
    }

    const cancelModal = () => {
        formikHelper.setFieldValue('current_information', formikHelper.initialValues.current_information)
        setShowModal(false)
    }

    const handleSubmitModal = () => {
        if (modalType === 'new') {
            formik.setFieldValue('current_information',
                [...formik.values.current_information, {...formikHelper.values}])
        } else if (modalType === 'edit') {
            let temp = formik.values.current_information;
            let index = temp.findIndex((item) => item?.uuid === formikHelper.values.uuid)
            if (index !== -1) {
                temp[index] = {...formikHelper.values}
                formik.setFieldValue('current_information', [...temp])
            }
        }
        setShowModal(false)
    }

    const removeRow = (uuid) => {
        let temp = [...formik.values.current_information].filter(item => item?.uuid !== uuid)
        formik.setFieldValue('current_information', [...temp])
    }

    const handleUploadFile = (file) => {
        if (file && file?.name) {
            let temp = formikHelper.values?.files?.length ? formikHelper.values.files : []
            formikHelper.setFieldValue('files', [...temp, {name: file.name}])
        }
        return null
    }

    return (
        <>
            <Modal size={'lg'} show={showModal} centered={true}
                   onHide={() => setShowModal(false)}>
                <Modal.Header>
                    <Modal.Title>
                        {modalType === 'new' ? 'Добавление' : 'Редактирование'} записи
                    </Modal.Title>
                    <button className={'close'} onClick={cancelModal}>
                        <FontAwesomeIcon icon={'times'}/>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <FormikProvider value={formikHelper}>
                        <Input label={labels.location} id={'location'} name={'location'}
                               value={formikHelper.values.location}
                               handleChangeValue={formikHelper.handleChange}/>

                        <Input label={labels.name} id={'name'} name={'name'}
                               value={formikHelper.values.name}
                               handleChangeValue={formikHelper.handleChange}/>

                        <Input label={labels.year} mask={'9999'} id={'year'} name={'year'}
                               value={formikHelper.values.year}
                               handleChangeValue={formikHelper.handleChange}/>

                        <Input label={labels.cost} id={'cost'} name={'cost'}
                               type={'number'}
                               value={formikHelper.values.cost}
                               handleChangeValue={formikHelper.handleChange}/>

                        <Input label={labels.details} id={'details'} name={'details'}
                               value={formikHelper.values.details}
                               handleChangeValue={formikHelper.handleChange}/>

                        <Input label={labels.usage} id={'usage'} name={'usage'}
                               value={formikHelper.values.usage}
                               handleChangeValue={formikHelper.handleChange}/>

                        <Input label={labels.files_url} id={'files_url'} name={'files_url'}
                               value={formikHelper.values.files_url}
                               handleChangeValue={formikHelper.handleChange}/>

                        <Input label={labels.comment} id={'comment'} name={'comment'}
                               value={formikHelper.values.comment}
                               handleChangeValue={formikHelper.handleChange}/>
                        <hr/>
                        <MyFileBrowser
                            handleUploadFile={handleUploadFile}
                            path={`root/storage/educational_spaces/educational_spaces_${id}/current/${formikHelper.values.uuid}`}
                            instanceId={`${formikHelper.values.uuid}`}
                            height={250}
                        />

                    </FormikProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'success'} onClick={handleSubmitModal}>
                        {modalType === 'new' ? 'Добавить' : 'Изменить'}
                    </Button>
                    <Button variant={'danger'}
                            onClick={cancelModal}>
                        Отменить
                    </Button>
                </Modal.Footer>
            </Modal>

            <Button variant={'success'} onClick={() => handleOpenModalInformation('new')}>
                <IconText text={'Добавить запись'} icon={'plus'}/>
            </Button>
            <hr/>
            <ReactTable bordered striped labels={labels} show_result={false}
                        data={{
                            results: formik.values.current_information
                        }}
                        additionalCheckField={additionalCheckField}
            />
        </>
    )
}
