import * as React from 'react'
import { Button, Card, Col, Dropdown, Modal, Row } from 'react-bootstrap'
import ReactPagination from '../../pagination/pagination'
import { useContext, useEffect, useState } from 'react'
import { getRequest } from '../../../actions/common'
import ReactTable from '../react_table'
import { useAbac } from 'react-abac'
import { AuthContext } from '../../../auth'
import { LoaderContext } from '../../../utils/loader'
import { ROLES } from '../../../rbac/constant'
import { SettingsButton, SettingsModalWindow } from './settings'
import { Export, ExportNiokr } from './export'
import { ShowAll } from './show_all'
import { ShowResults } from './show_results'
import { TableModal } from './table_modal'
import { AppoveImport, ImportTable } from './import'

const ReactFilterTable = (props) => {
    const { userHasPermissions } = useAbac()
    const user = useContext(AuthContext)
    const setShowLoading = useContext(LoaderContext)

    const [ordering, setOrdering] = useState(localStorage.getItem(`ordering_${props.get_title}`))
    const [filtering, setFiltering] = useState(localStorage.getItem(`filtering_${props.get_title}`)
        ? JSON.parse(localStorage.getItem(`filtering_${props.get_title}`))
        : {})
    const [currentPage, setCurrentPage] = useState(Number(props.page))
    const [showFilter, setShowFilter] = useState(false)
    const [showSort, setShowSort] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [firstInitial, setFirstInitial] = useState(false)
    const [urlImport, setUrlImport] = useState(null)
    const [urlExport, setUrlExport] = useState(null)
    const [needReload, setNeedReload] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [file, setFile] = useState(null)

    const [pageLimit, setPageLimit] = useState(10)
    const [groups, setGroups] = useState(localStorage.getItem(`groups_${props.get_title}`)
        ? JSON.parse(localStorage.getItem(`groups_${props.get_title}`))
        : props.groups)

    useEffect(() => {
        if (props?.filtering?.reset_filters) {
            setFiltering({})
            setOrdering('')
            setPageLimit(10)
        }
    }, [props.filtering])

    useEffect(() => {
        if (props?.urlImport) {
            setUrlImport(props?.urlImport)
        }
        if (props?.urlExport) {
            setUrlExport(props?.urlExport)
        }
    }, [props.urlImport, props.urlExport])

    const getActiveFields = () => {
        let labels = {}
        props.groups['Отображаемые колонки'].forEach((element) => {
            labels[element] = props.labels[element]
        })
        return labels
    }

    useEffect(() => {
        if (localStorage.getItem('groups_staff') && !firstInitial) {
            props.setGroups(groups)
        }
        setFirstInitial(true)
        localStorage.setItem(`groups_${props.get_title}`, JSON.stringify(groups))
    }, [groups])

    useEffect(() => {
        if (filtering instanceof Object || needReload) {
            if (pageLimit > 10) {
                setShowLoading(true)
            }
            if (user && userHasPermissions(props.permission)) {
                getRequest(props.get_title, props.setFunc, {
                    page: currentPage,
                    ordering: ordering,
                    page_size: pageLimit,
                    ...filtering,
                    ...props?.filtering,
                }, '', null, null, false, false).then((r) => {
                    if (r?.response?.status === 404 && r?.response?.data?.detail
                        === 'Неправильная страница')
                        setCurrentPage(1)
                    setShowLoading(false)
                })
                setNeedReload(false)
            } else if (user) {
                delete filtering?.project
                getRequest(props.get_title, props.setFunc, {
                    page: currentPage,
                    ordering: ordering,
                    project: user?.project?.id,
                    page_size: pageLimit,
                    ...filtering,
                    ...props?.filtering,
                }, '', null, null, false, false).then((r) => {
                    if (r?.response?.status === 404 && r?.response?.data?.detail
                        === 'Неправильная страница')
                        setCurrentPage(1)
                    setShowLoading(false)
                })
            }
            localStorage.setItem(`filtering_${props.get_title}`, JSON.stringify(filtering))
            localStorage.setItem(`ordering_${props.get_title}`, ordering)
        }

    }, [ordering, currentPage, filtering, props.filtering, pageLimit, needReload])

    return <React.Fragment>
        <TableModal setFunc={setShowFilter} show={showFilter} icon={'filter'}
                    title={'Сохранить / изменить таблицу Filter'}/>
        <TableModal setFunc={setShowSort} show={showSort} icon={'sort'}
                    title={'Сохранить / изменить таблицу Sort'}/>
        <SettingsModalWindow props={props}
                             showSettings={showSettings}
                             setShowSettings={setShowSettings}
                             groups={groups}
                             setGroups={setGroups}
                             pageLimit={pageLimit}
                             setPageLimit={setPageLimit}
        />
        { urlImport
            && <AppoveImport file={file}
                            setFile={setFile}
                            showModal={showModal}
                            setShowModal={setShowModal}
                            getTitle={props.get_title}
                            urlImport={urlImport}
                            setNeedReload={setNeedReload} 
                            setShowLoading={setShowLoading}
              />
        }
        
        <Card>
            <Card.Header className={'text-right'}>
                <ShowResults data={props.data}
                             page={props.page}
                             pageLimit={pageLimit}
                />
            </Card.Header>
            <Card.Body>
                <div className={'text-right mb-3'}>
                    { urlImport
                        && <ImportTable getTitle={props.get_title}
                                        templateFile={props.templateFile}
                                        urlImport={urlImport}
                                        setNeedReload={setNeedReload}
                                        setShowLoading={setShowLoading}
                                        file={file}
                                        setFile={setFile}
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                            />
                    }
                    <SettingsButton setShowSettings={setShowSettings}/>
                    
                    { urlExport && props.get_title === 'niokr'
                        ? <ExportNiokr getTitle={props.get_title} urlExport={urlExport}/>
                        : <Export getTitle={props.get_title}/>
                    }
                    <ShowAll totalCount={props?.data?.count}
                             pageLimit={pageLimit}
                             setPageLimit={setPageLimit}
                             setCurrentPage={setCurrentPage}
                    />
                </div>
                <div className='table-responsive kv-grid-container'>
                    <ReactTable
                        id={'tblToExcl'}
                        data={props.data} bordered={true} page={currentPage} pageSize={10}
                        fields={props.fields}
                        labels={getActiveFields()}
                        update_left={false}
                        show_result={false}
                        url_update={props.url_update}
                        url_field={props.url_field}
                        is_show={true}
                        is_see_field={false}
                        sort={true}
                        get_title={props.get_title}
                        update_params={{ state: null }}
                        setFunc={props.setFunc}
                        additionalCheckField={props.checkField}
                        permission={props.permission}
                        is_reset={props?.filtering?.reset_filters}
                        ordering={ordering}
                        is_update={!user?.roles?.includes(ROLES.management)}
                        setOrdering={setOrdering}
                        setFiltering={setFiltering}
                        colorExpression={props.colorExpression}
                    />
                </div>
            </Card.Body>
            <Card.Footer>
                <ReactPagination
                    className='pagination-bar'
                    currentPage={currentPage}
                    totalCount={props.data ? props.data.count : null}
                    pageSize={pageLimit}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            </Card.Footer>
        </Card>
    </React.Fragment>
}

export default ReactFilterTable
