import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../../components/breadcrumbs'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { IconText } from '../../../../components/icon_txt'
import { getRequest, putRequest, updateRequest } from '../../../../actions/common'
import { Area, Input } from '../../../../components/form/forms'
import { PERMISSIONS } from '../../../../rbac/constant'
import { useAbac } from 'react-abac'
import {
    ERRORS_MESSAGES,
    REQUIRED_FIELDS,
    REQUIRED_FIELDS_TEMP,
    RID_DOCUMENT_TYPES,
    RID_FINANCING_METHOD,
    RID_STATUS,
    RID_STATUS_APPLICATION,
    RID_STATUS_RECEPTION,
    RID_TYPES,
    STATUS_ACCEPT,
    STATUS_APPROVE,
} from './constant'
import { btnMessage, findInSelectData, getLabelFromOptions } from '../../../../utils/utils'
import { MyFileBrowser } from '../../../../components/file_manager/file_manager'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikDate, FormikSelect } from '../../../../components/form/formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const RidInternalItem = ({
                                    data,
                                    setData,
                                    user,
                                    disabled,
                                    messages,
                                    handleChangeValue,
                                    generateOptions,
                                    ErrorsBlock,
                                    MessageHistory,
                                    ActionButtons,
                                    DisableButton,
                                    handleSubmit,
                                    HeaderStatus,
                                }) => {
    const params = useParams()
    const navigate = useNavigate()
    const project_ref = useRef()
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [showContinueModal, setShowContinueModal] = useState(false)

    const { userHasPermissions } = useAbac()
    const [select_data, setSelectData] = useState({})

    const select_fields = ['project', 'event', 'patent_office']
    const given_select_fields = ['type', 'fin_method', 'application_status', 'document_type']


    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'title_ru': '',
        'title_en': '',
        'type': null,
        'fin_method': null,
        'document_type': null,
        'application_status': null,
        'date': null,
        'number': '',
        'application_date': null,
        'application_number': '',
        'related_docs_url': '',
        'organization_name': '',
        'authors_input': '',
    })

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return saveRid()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    let CAN_MANAGE_APPLICATION = userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
        || (formik.values['application_status'] === RID_STATUS_APPLICATION)
        || !formik.values['application_status']

    useEffect(() => {
        if (data?.status
            && formik.values.status > data?.status
            && formik.values.status === STATUS_ACCEPT) {
            setShowContinueModal(true)
        } else {
            handleSubmit(formik, saveRid)
        }
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД РИДы | ИС «ПИШ»`
            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })
            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }
            if (!set_selected?.patent_office_id) {
                set_selected.patent_office_id = 10
            }
            formik.setValues({
                ...set_inputs,
                ...set_selected,
                without_message: true,
                current_status: data.status,
            })
        }
    }, [data])

    useEffect(() => {
        if (data) {
            let is_manager = user?.roles.every((value) => value === 'project_manager')  // can't view all projects
            if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
                for (let field of select_fields) {
                    if (!Object.keys(select_data).includes(field) && field !== 'event') {
                        if (field === 'staff') {
                            getRequest('staff_select', setSelectData, {}, '', select_data, field)
                        } else if (field === 'project' && is_manager) {
                            getRequest('project', setSelectData, {
                                type_data: 'user_projects',
                                'user_id': user.id,
                            }, '', select_data, field)
                        } else {
                            getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                        }
                        break
                    }

                    if (!select_data?.event?.length && field === 'event' && formik.values?.project_id) {
                        getRequest('event', setSelectData, {
                            type_data: 'all',
                            project: formik.values?.project_id,
                        }, '', select_data, 'event').then((r) => {
                            if (formik.values?.event_id && !findInSelectData(r.data, 'id', formik.values?.event_id)) {
                                setInputFields({ ...formik.values, event_id: null })
                            }
                        })
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values])

    useEffect(() => {
        if (formik.values?.project_id !== project_ref.current && Object.keys(select_data).includes('event')) {
            // formik.setFieldValue('event_id', null);
            setSelectData({ ...select_data, event: [] })
        }
        if (formik.values?.project_id)
            project_ref.current = formik.values?.project_id
    }, [formik.values])

    useEffect(() => {
        const specificErrors = [
            'application_number',
            'fips_empty',
            'application_date',
            'number',
            'date',
            'registration_empty',
            'patent_empty',
        ]

        const hasSpecificErrors = specificErrors.some((key) => formik.errors[key])

        if (hasSpecificErrors) {
            setShowErrorModal(true)
        }
    }, [formik.errors])

    const saveRid = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_by: user.id }
        } else if (STATUS_APPROVE === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_rid_by: user.id }
        } else {
            payload = formik.values
        }

        updateRequest('rid_internal', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/rid/rid-internal')
                setData(response.data)
            }
        })
        if (formik?.values?.message) {
            putRequest('rid_internal_messages', {
                content: formik?.values?.message,
                rid_status: data.status,
                status: data.status,
                created_by: user.id,
                rid: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }

        return Promise.resolve('is_saved')
    }

    const DocumentAlert = () => {
        return <div className={'alert alert-danger'}>
            В поле "Вид документа" выбрано
            "{getLabelFromOptions(RID_DOCUMENT_TYPES, formik.values.document_type)}"<br/>
            Для отправки на согласование Дирекции необходимо:<br/>
            <ul>
                {ERRORS_MESSAGES[formik.values.document_type].map((msg, index) => <li key={`error-msg-${index}`}>
                    заполнить поле "{msg.field}" в разделе {msg.isFile ? 'Прикрепленные файлы' : 'Основная информация'}
                </li>)}
            </ul>
            Если хоть одно поле не заполнено, то мы не можем отправить эту запись на согласование к
            дирекции. Проверьте, пожалуйста, заполнение этих полей
        </div>
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <FormikProvider value={formik}>
                <Modal size={'md'} show={showErrorModal} centered={true}
                       onHide={() => setShowErrorModal(false)}
                >
                    <div className={'modal-content'}>
                        <Modal.Header>
                            <Modal.Title>
                                ОШИБКА
                            </Modal.Title>

                            <button className={'close'}
                                    onClick={() => setShowErrorModal(false)}
                            >
                                <FontAwesomeIcon icon={'times'}/>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <DocumentAlert/>
                        </Modal.Body>
                    </div>
                </Modal>

                <Modal size={'md'} show={showContinueModal} centered={true}
                       onHide={() => setShowContinueModal(false)}
                >
                    <div className={'modal-content'}>
                        <Modal.Header>
                            <Modal.Title>
                                ВНИМАНИЕ
                            </Modal.Title>

                            <button className={'close'}
                                    onClick={() => setShowContinueModal(false)}
                            >
                                <FontAwesomeIcon icon={'times'}/>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            Вы уверены, что все поля в записи заполнены верно?
                            После подтверждения в этом уведомлении менеджер больше не сможет редактировать эту запись.
                        </Modal.Body>
                        <Modal.Footer>
                            <div className={'button-actions'}>
                                <Button variant={'success'}
                                        onClick={() => {
                                            saveRid().then()
                                            setShowContinueModal(false)
                                        }}>
                                    Да, все верно
                                </Button>
                                <Button variant={'warning'} onClick={() => {
                                    setShowContinueModal(false)
                                }}>
                                    Отменить
                                </Button>
                            </div>
                        </Modal.Footer>
                    </div>
                </Modal>

                <Form>
                    <div className='section-header sticky-top'>
                        <h1 className='page-header'>
                            {`Редактирование записи №${params.id} в БД РИДы`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_RID}
                                       permission_manage={PERMISSIONS.MANAGE_RID}
                        />
                    </div>
                    <div
                        className='mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center'>
                        <HeaderStatus style={{ minWidth: '250px' }}/>
                    </div>
                    <Button variant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                            text={'Сообщения'}/></Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='rid_message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          disabled={disabled}
                                          rows='5'
                                          value={formik?.values?.message}
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <legend>Основная информация</legend>
                                <Row>
                                    <Col md={12}>
                                        <Input label={'Наименование созданного РИД'} id={'title_ru'}
                                               name={'title_ru'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('title_ru') : false}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values.title_ru : null}
                                               disabled={disabled}
                                               error={formik.errors['title_ru']}
                                               invalid={formik.errors['title_ru']}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Field component={FormikSelect} name={'type'}
                                               label={'Вид РИД'} id={'type'}
                                               isSearchable={true}
                                               options={RID_TYPES}
                                               error={formik.errors['type_id']}
                                               invalid={formik.errors['type_id']}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('type') : false}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Field component={FormikSelect} name={'document_type'}
                                               label={'Вид документа'} id={'document_type'}
                                               isSearchable={true}
                                               options={RID_DOCUMENT_TYPES}
                                               error={formik.errors['document_type_id']}
                                               invalid={formik.errors['document_type_id']}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('document_type') : false}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Field component={FormikSelect} name={'fin_method'}
                                               label={'Способ финансирования'} id={'fin_method'}
                                               isSearchable={true}
                                               options={RID_FINANCING_METHOD}
                                               error={formik.errors['fin_method_id']}
                                               invalid={formik.errors['fin_method_id']}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('fin_method') : false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Field component={FormikSelect} name={'project_id'} label={'Проект'}
                                               id={'project_id'}
                                               isSearchable={true}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               error={formik.errors['project']}
                                               invalid={formik.errors['project']}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('project') : false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Field component={FormikSelect}
                                               name={'application_status'} label={'Статус подачи'}
                                               id={'application_status'}
                                               isSearchable={true}
                                               options={RID_STATUS}
                                               error={formik.errors['application_status_id']}
                                               invalid={formik.errors['application_status_id']}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('application_status') : false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input label={'Заявленный правообладатель'} id={'organization_name'}
                                               name={'organization_name'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('organization') : false}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values.organization_name : null}
                                               disabled={disabled}
                                               error={formik.errors['organization_name']}
                                               invalid={formik.errors['organization_name']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'patent_office_id'}
                                               label={'Страна действия патента'} id={'patent_office_id'}
                                               isSearchable={true}
                                               options={generateOptions('patent_office', (element) => {
                                                   if (element?.title_short)
                                                       return `${element.title} (${element.title_short})`
                                                   else
                                                       return `${element.title}`
                                               }, select_data)}
                                               error={formik.errors?.patent_office}
                                               invalid={formik.errors?.patent_office}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('patent_office') : false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input label={'Номер заявки'} id={'application_number'}
                                               name={'application_number'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('application_number') : false}
                                               handleChangeValue={formik.handleChange}
                                               disabled={disabled || !CAN_MANAGE_APPLICATION}
                                               error={formik.errors['application_number']}
                                               invalid={formik.errors['application_number']}
                                               value={formik.values ? formik.values.application_number : null}/>
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate} name={'application_date'}
                                               label={'Дата получения заявки'} id={'application_date'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('application_date') : false}
                                               handleChangeValue={formik.handleChange}
                                               disabled={disabled || !CAN_MANAGE_APPLICATION}
                                               error={formik.errors['application_date']}
                                               invalid={formik.errors['application_date']}
                                               value={formik.values ? formik.values.application_date : null}/>
                                    </Col>
                                </Row>
                                {formik.values['application_status'] === RID_STATUS_RECEPTION
                                    && <Row>
                                        <Col md={6}>
                                            <Input label={'Номер охранного документа'} id={'number'} name={'number'}
                                                   required={data ? REQUIRED_FIELDS[data.status + 1].includes('number') : false}
                                                   handleChangeValue={formik.handleChange}
                                                   disabled={disabled}
                                                   error={formik.errors['number']}
                                                   invalid={formik.errors['number']}
                                                   value={formik.values ? formik.values.number : null}/>
                                        </Col>
                                        <Col md={6}>
                                            <Field component={FormikDate} name={'date'}
                                                   label={'Дата получения охранного документа'} id={'date'}
                                                   required={data ? REQUIRED_FIELDS[data.status + 1].includes('date') : false}
                                                   handleChangeValue={formik.handleChange}
                                                   small={'Дата регистрации охранного документа'}
                                                   disabled={disabled}
                                                   error={formik.errors['date']}
                                                   invalid={formik.errors['date']}
                                                   value={formik.values ? formik.values.date : null}/>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col md={12}>
                                        <Area label={'ФИО авторов'} id={'authors_input'}
                                              name={'authors_input'}
                                              required={data ? REQUIRED_FIELDS[data.status + 1].includes('authors') : false}
                                              handleChangeValue={formik.handleChange}
                                              value={formik.values ? formik.values.authors_input : null}
                                              disabled={disabled}
                                              error={formik.errors['authors_input']}
                                              invalid={formik.errors['authors_input']}
                                        />
                                    </Col>
                                </Row>
                                <Input label={'Ссылка на сопутствующие документы'} id={'related_docs_url'}
                                       name={'related_docs_url'}
                                       handleChangeValue={formik.handleChange}
                                       disabled={disabled}
                                       small={'Скан документа, подтверждающий поле «Вид документа»'}
                                       value={formik.values ? formik.values.related_docs_url : null}/>
                            </fieldset>
                            <hr/>
                            <fieldset>
                                <legend>Прикрепленные файлы</legend>
                                <Row>
                                    <Col md={6}>
                                        <h5>Письмо о подаче заявки</h5>
                                        <small>
                                            Пожалуйста, загружайте файл с названием
                                            "[Фамилия руководителя]_Письмо о подаче
                                            заявки_[сокращенное название РИД]"
                                        </small>
                                        <MyFileBrowser
                                            height={280}
                                            path={`root/storage/rid/rid_${params.id}/application_letter`}
                                            tags={{
                                                'document_type': 'letter',
                                                'year': new Date().getFullYear(),
                                                'month': new Date().getMonth() + 1,
                                                'project': formik.values.project_id,
                                                'rid_item': params.id,
                                            }}
                                            read_only={disabled}
                                            instanceId={'application_letter'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <h5>Свидетельство о государственной регистрации</h5>
                                        <small>
                                            Пожалуйста, загружайте файл с названием
                                            "[Фамилия руководителя]_Свидетельство о
                                            государственной регистрации_[сокращенное название РИД]"
                                        </small>
                                        <MyFileBrowser
                                            height={280}
                                            path={`root/storage/rid/rid_${params.id}/registration_certificate`}
                                            tags={{
                                                'document_type': 'certificate',
                                                'year': new Date().getFullYear(),
                                                'month': new Date().getMonth() + 1,
                                                'project': formik.values.project_id,
                                                'rid_item': params.id,
                                            }}
                                            read_only={disabled}
                                            setIsEmpty={(v) => formik.setFieldValue('registration_empty', v)}
                                            instanceId={'registration_certificate'}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col md={6}>
                                        <h5>Уведомление ФИПС</h5>
                                        <small>
                                            Пожалуйста, загружайте файл с названием
                                            "[Фамилия руководителя]_Уведомление
                                            ФИПС_[сокращенное название РИД]"
                                        </small>
                                        <MyFileBrowser
                                            height={280}
                                            path={`root/storage/rid/rid_${params.id}/fips_notification`}
                                            read_only={disabled}
                                            tags={{
                                                'document_type': 'notice',
                                                'year': new Date().getFullYear(),
                                                'month': new Date().getMonth() + 1,
                                                'project': formik.values.project_id,
                                                'rid_item': params.id,
                                            }}
                                            borderInvalid={!!formik.errors?.fips_empty}
                                            setIsEmpty={(v) => formik.setFieldValue('fips_empty', v)}
                                            instanceId={'fips_notification'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <h5>Патент</h5>
                                        <small>
                                            Пожалуйста, загружайте файл с названием
                                            "[Фамилия руководителя]_Патент_
                                            [сокращенное название РИД]"
                                        </small>
                                        <MyFileBrowser
                                            height={280}
                                            path={`root/storage/rid/rid_${params.id}/patent`}
                                            tags={{
                                                'document_type': 'patent',
                                                'year': new Date().getFullYear(),
                                                'month': new Date().getMonth() + 1,
                                                'project': formik.values.project_id,
                                                'rid_item': params.id,
                                            }}
                                            read_only={disabled}
                                            setIsEmpty={(v) => formik.setFieldValue('patent_empty', v)}
                                            instanceId={'patent'}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col md={12}>
                                        <h5>Дополнительные документы</h5>
                                        <small>
                                            Пожалуйста, загружайте файл с названием
                                            "[Фамилия руководителя]_[какой
                                            документ]_[сокращенное название РИД]"
                                        </small>
                                        <MyFileBrowser
                                            path={`root/storage/rid/rid_${params.id}/other`}
                                            tags={{
                                                'document_type': 'document',
                                                'year': new Date().getFullYear(),
                                                'month': new Date().getMonth() + 1,
                                                'project': formik.values.project_id,
                                                'rid_item': params.id,
                                            }}
                                            read_only={disabled}
                                            instanceId={'other'}
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
}
