import { Button, Form, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field, FormikProvider, useFormik } from 'formik'
import { Check, Input } from '../../form/forms'
import { FormikDate, FormikSelect } from '../../form/formik'
import { PERMISSIONS } from '../../../rbac/constant'
import { IconText } from '../../icon_txt'
import ReactTable from '../../tables/react_table'
import * as React from 'react'
import { useEffect, useState } from 'react'
import {
    CAN_MANAGE_ROLES,
    MoneySchema,
    MONTHS,
    STATUS_CHECK_ACTS,
    YEARS,
} from '../../../screens/database/financing/constant'
import { deleteRequest, getRequest, putRequest, updateRequest } from '../../../actions/common'
import { useAbac } from 'react-abac'
import { getLabelFromOptions } from '../../../utils/utils'
import { MyFileBrowser } from '../../file_manager/file_manager'
import { FileList } from '../../file_manager/file_handlers'

export const MoneyOrders = ({ disabled, fin_id, project_id, moneyOrdersData, setMoneyOrder, isAct = false, user }) => {
    const { userHasPermissions } = useAbac()
    const [showModal, setShowModal] = useState(false)
    const [data, setData] = useState(moneyOrdersData)

    const title = {
        nominativeCase: isAct ? 'Акт' : 'Платежное поручение',
        genitiveCase: isAct ? 'акта' : 'платежного поручения',
        plural: isAct ? 'Акты' : 'Платежные поручения',
    }
    const fileFolder = `root/storage/financing/${!isAct ? `financing_orders_${fin_id}` : `financing_acts_${fin_id}`}`

    const moneyOrdersLabels = {
        serial_number: '№',
        number: `Номер ${title.genitiveCase}`,
        date: `Дата ${title.genitiveCase}`,
        summary: `Сумма ${title.genitiveCase}`,
        include_month: 'Месяц зачета',
        include_year: 'Года зачета',
        created_at: 'Дата добавления',
        is_checked: 'Проверено',
        file: `Файл ${title.genitiveCase}`,
        actions: 'Редактировать / удалить',
    }


    const formMoney = useFormik({
        validationSchema: MoneySchema, initialValues: {
            is_act: isAct, is_checked: false, object_number: 0, number: '', data: undefined, summary: null,
        }, onSubmit: (values) => {
            return saveModal(values, 'financing_orders', data, setMoneyOrder, formMoney, setShowModal)
        }, enableReinitialize: true, validateOnChange: false, validateOnBlur: false,
    })

    useEffect(() => {
        if (moneyOrdersData?.results) {
            let set = [...moneyOrdersData?.results].filter((item) => item?.is_act === isAct)
            setData({
                count: set.length, results: set,
            })
        }
    }, [moneyOrdersData])

    const saveModal = (values, url, len, setFunc, form, setModal) => {
        const requestData = {
            ...values,
            financing: values?.is_edit ? undefined : fin_id,
            is_act: isAct,
            object_number: values?.is_edit ? undefined : len?.results?.length + 1,
        }

        const request = values?.is_edit
            ? updateRequest('financing_orders', { common: requestData }, values?.id)
            : putRequest(url, requestData)

        request.then(() => {
            getRequest(url, setFunc, { financing: fin_id }).then(() => {
                cancelModal(form, setModal)
            })
        })
    }

    const cancelModal = (data, setFunc) => {
        data.setValues(data.initialValues)
        setFunc(false)
    }

    const handleDelete = (item) => {
        deleteRequest('financing_orders', item?.id).then((r) => {
            getRequest('financing_orders', setMoneyOrder, { financing: fin_id }).then()
        })
    }

    const handleEdit = (item) => {
        formMoney.setValues({
            id: item.id,
            serial_number: item.serial_number,
            number: item.number,
            date: item.date,
            summary: item.summary,
            include_month: item.include_month,
            include_year: item.include_year,
            is_edit: true,
        })
        setShowModal(true)
    }

    const handleCheck = (item, checked) => {
        updateRequest('financing_orders', {
            common: {
                is_checked: checked,
            },
        }, item?.id).then()
    }

    const additionalCheckField = (field, key, element) => {
        if (key === 'actions') {
            return element?.is_checked && userHasPermissions(PERMISSIONS.IS_MANAGER) ? ' ' : (<>
                <Button className={'mr-3'} variant={'secondary'} onClick={() => handleEdit(element)}>
                    <FontAwesomeIcon icon={'pencil-alt'}/>
                </Button>
                <Button variant={'danger'} onClick={() => handleDelete(element)}>
                    <FontAwesomeIcon icon={'trash-alt'}/>
                </Button>
            </>)
        } else if (key === 'is_checked') {
            if (user?.roles?.some((role) => CAN_MANAGE_ROLES[STATUS_CHECK_ACTS].includes(role))) {
                return (<Check value={field}
                               handleChangeValue={(e) => handleCheck(element, e?.target?.checked)}/>)
            } else {
                return field
            }
        } else if (key === 'include_month') {
            return getLabelFromOptions(MONTHS, field)
        } else if (key === 'file') {
            return <FileList files={[{ name: '' }]} folder={`${fileFolder}/${element?.id}`}/>
        }
        return field
    }


    return (<>
        <Modal size={'lg'} show={showModal} centered={true}
               onHide={() => cancelModal(formMoney, setShowModal)}>
            <Modal.Header>
                <Modal.Title>
                    Добавление {title.genitiveCase}
                </Modal.Title>
                <button className={'close'} onClick={() => {
                    cancelModal(formMoney, setShowModal)
                }}><FontAwesomeIcon icon={'times'}/></button>
            </Modal.Header>
            <Modal.Body>
                <FormikProvider value={formMoney}>
                    <Input label={`Номер ${title.genitiveCase}`}
                           handleChangeValue={formMoney.handleChange}
                           value={formMoney.values.number}
                           error={formMoney.errors['number']}
                           invalid={formMoney.errors['number']}
                           id={'number'}
                           name={'number'}
                           required={true}
                    />
                    <Field component={FormikDate} name={'date'}
                           handleChangeValue={formMoney.handleChange}
                           label={`Дата ${title.genitiveCase}`}
                           id={'date'}
                           error={formMoney.errors['date']}
                           invalid={formMoney.errors['date']}
                           value={formMoney.values.date}
                           required={true}
                    />
                    <Input type={'number'}
                           label={`Сумма ${title.genitiveCase}`}
                           handleChangeValue={formMoney.handleChange}
                           value={formMoney.values.summary}
                           error={formMoney.errors['summary']}
                           invalid={formMoney.errors['summary']}
                           id={'summary'}
                           name={'summary'}
                           required={true}
                    />
                    <Field component={FormikSelect}
                           name={'include_month'}
                           label={'Месяц зачета'}
                           id={'include_month'}
                           required={true}
                           isSearchable={true}
                           isClearable={true}
                           options={MONTHS}
                           error={formMoney?.errors['include_month']}
                           invalid={formMoney?.errors['include_month']}
                    />
                    <Field component={FormikSelect}
                           name={'include_year'}
                           label={'Год зачета'}
                           id={'include_year'}
                           required={true}
                           isSearchable={true}
                           isClearable={true}
                           options={YEARS}
                           error={formMoney?.errors['include_year']}
                           invalid={formMoney?.errors['include_year']}
                    />
                    {formMoney.values.is_edit && formMoney.values?.id ? (<div className={'form-group required'}>
                        <Form.Label>Файлы</Form.Label>
                        <MyFileBrowser
                            path={`${fileFolder}/${formMoney.values?.id}`}
                            tags={{
                                document_type: isAct ? 'act' : 'order',
                                year: formMoney.values?.include_year,
                                month: formMoney.values?.include_month,
                                project: project_id,
                                financing_item: fin_id,
                                act_num: formMoney.values?.number,
                            }}
                            read_only={disabled}
                            instanceId={`${formMoney.values?.id}`}
                            height={250}
                        />
                    </div>) : null}
                </FormikProvider>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'success'} onClick={formMoney.submitForm}>
                    {formMoney.values.is_edit ? 'Сохранить' : 'Добавить'}
                </Button>
                <Button variant={'danger'}
                        onClick={() => cancelModal(formMoney, setShowModal)}>
                    Отменить
                </Button>
            </Modal.Footer>
        </Modal>
        <h5>{title.plural}</h5>
        {disabled ? null : <Button className={'mb-3'} onClick={() => setShowModal(true)}>
            <IconText text={`Добавить ${title.nominativeCase}`} icon={'plus'}/>
        </Button>}
        <ReactTable
            bordered={true}
            hover={true}
            striped={true}
            additionalCheckField={additionalCheckField}
            show_result={false}
            data={data}
            labels={moneyOrdersLabels}/>
        <hr/>
    </>)
}

