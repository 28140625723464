import { useContext } from 'react'
import { AuthContext } from '../auth'
import * as React from 'react'
import { delete_cols, delete_row, set_cols_width } from './excel'
import * as XLSX from 'xlsx/xlsx.mjs'

export const setChangedValue = (setChanges, changes, target, key, value, sub_key = '', list_index = null) => {
    let new_changes = changes
    if (!new_changes[target] && target) {
        new_changes[target] = {}
    }
    if (sub_key) {
        if (list_index === null) {
            new_changes[target][sub_key]
                ? new_changes[target][sub_key][key] = value
                : new_changes[target][sub_key] = {}
        } else {
            let temp = []
            new_changes[target][sub_key]
                ? temp[list_index] = { ...new_changes[target][sub_key][list_index], ...{ [key]: value } }
                : null
            new_changes[target][sub_key]
                ? new_changes[target][sub_key][list_index] = { ...new_changes[target][sub_key][list_index], ...{ [key]: value } }
                : new_changes[target][sub_key] = temp
        }
    } else if (target) {
        new_changes[target][key] = value
    } else {
        new_changes[key] = value
    }
    setChanges(new_changes)
}

export const isValue = (value) => {
    return value ? true : false
}

export const isInt = (n) => {
    return n % 1 === 0
}

export const convertBackendDate = (dateStr) => {
    if (typeof dateStr === 'string' && dateStr.includes('.')) {
        let splitDate = dateStr.split('.')
        let date = new Date(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`)
        return date ? date : null
    }
}

export const formatDate = (dateStr) => {
    if (typeof dateStr === 'string' && dateStr.includes('-')) {
        let splitDate = dateStr
        if (dateStr.includes('T')) {
            splitDate = dateStr.split('T')[0]
        }
        splitDate = splitDate.split('-')

        return `${splitDate[2]}.${splitDate[1]}.${splitDate[0]}`
    }
    return dateStr
}

export const btnMessage = () => {
    let card = document.getElementById('card-message')
    if (card && card.style.display === 'none') {
        card.style.display = 'block'
    } else if (card && card.style.display === 'block') {
        card.style.display = 'none'
    }
}

export const getValueByKey = (dict, key, element = null) => {
    if (dict) {
        for (let index in dict) {
            if (dict[index].key === key) {
                return element ? dict[index][element] : dict[index]
            }
        }
    }
}

export const getLabelFromOptions = (options, value) => {
    for (let index in options) {
        if (options[index]?.value === value) {
            return options[index]?.label
        }
    }
}


export const findInSelectData = (data, key, value) => {
    for (let index in data) {
        if (data[index][key] === value) {
            return data[index]
        }
    }
}

export const Messages = ({ messages_, status_labels, source, type }) => {
    const user = useContext(AuthContext)
    let blockquotes = []
    if (messages_) {
        for (let message in messages_) {
            if ((messages_[message]?.type && messages_[message]?.type !== type)
                || (type && !messages_[message]?.type)) continue

            let status_name = `${source}_status`
            blockquotes.push(<blockquote key={messages_[message].id} className={`blockquote 
                ${messages_[message].created_by === user?.fio 
                    ? 'blockquote_self' 
                    : 'blockquote_interlocutor'}`}>
                {messages_[message].content}
                <footer className='blockquote-footer'>
                    <strong>{messages_[message].created_by} </strong>
                    от {messages_[message].created_at}.
                    ({status_labels[messages_[message]?.status]})
                </footer>
            </blockquote>)
        }
    }
    if (!blockquotes.length) {
        blockquotes = <blockquote className='m-2'>Нет сообщений</blockquote>
    }
    return <div className='message-history'>
        {blockquotes}
    </div>
}


export const setRequiredField = (field, status_required, is_array = false) => {
    if (field && !isNaN(status_required)) {
        if (is_array)
            return field?.when(['status'], (status, schema) => {
                return (status[0] >= status_required)
                    ? schema?.required('Обязательное поле!').min(1, 'Обязательное поле!')
                    : schema
            })
        else return field?.when(['status'], (status, schema) => {
            return (status[0] >= status_required) ? schema?.required('Обязательное поле!') : schema
        })
    } else {
        return null
    }
}

export const downloadPage = (type, id, name, isDatabase) => {
    if (type !== 'json') {
        let data = document.getElementById(id)
        let dataClone = data.cloneNode(true)

        if (name.includes('grant')) {
            const rows = dataClone.querySelectorAll('tr')
            for (const row of rows) {
                let cols = row.querySelectorAll('td')
                for (const col of cols) {
                    if (col.children.length > 0 
                        && !col.innerText 
                        && col.lastChild.childNodes.length > 2) {
                        col.replaceChildren(col.lastChild.childNodes[1].defaultValue)
                    }
                }    
            }
        }
        
        let excelFile = XLSX.utils.table_to_book(dataClone, {
            sheet: 'sheet1',
            dateNF: 'mm.dd.yyyy',
            cellDates: false,
            raw: true,
        })
        let ws = excelFile.Sheets['sheet1']
        if (isDatabase) {
            delete_row(ws, 1)
            delete_cols(ws, 0, 1)
        }
        set_cols_width(ws)
        XLSX.writeFile(excelFile, `${name}.${type}`)
    }
}

export const getUuid = () => {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16),
    )
}

export const getRandomString = (length) => {
    let result = ''
    const CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const CHARACTERS_LENGTH = CHARACTERS.length
    let counter = 0
    while (counter < length) {
        result += CHARACTERS.charAt(Math.floor(Math.random() * CHARACTERS_LENGTH))
        counter += 1
    }
    return result
}
